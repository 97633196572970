import React, { useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import DraggableList from './DraggableList';
import AuctionSelector from '../AuctionSelector/AuctionSelector';


const OrderLotesScreen = () => {
  const [selectedLeilaoId, setSelectedLeilaoId] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para controle de carregamento
  
  const handleLeilaoSelect = (id) => {
    console.log('Selected Leilao ID:', id);
    setSelectedLeilaoId(id); // Atualiza o ID selecionado
  };

  
  return (    
    <View style={styles.container}>
      <Text style={styles.title}>Organizar Lotes</Text>
      <AuctionSelector onLeilaoSelect={handleLeilaoSelect} />    
      {selectedLeilaoId ? (
        <View>
          <DraggableList idLeilao={selectedLeilaoId} />         
        </View>
      ) : (
        <View style={styles.messageContainer}>
          <Text style={styles.messageText}>Por favor, selecione um leilão.</Text>
        </View>
      )}
      
      {loading && <ActivityIndicator size="large" color="#007BFF" style={styles.loader} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f8f9fa', // Cor de fundo clara
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 20,
    textAlign: 'center',
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  loader: {
    marginTop: 20,
  },
});

export default OrderLotesScreen;
