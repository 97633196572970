import React, { useEffect, useState, useRef } from 'react';
import { View, Text, FlatList, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { getLotesparaOrdem } from '../../Services/api/AuthServices';
import { theme } from '../../Global/Theme';
import { Modal } from 'react-native'; 
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CancelIcon from '@mui/icons-material/Cancel';
import GavelIcon from '@mui/icons-material/Gavel';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Avatar, Button, ButtonGroup, Chip, Tab, Tabs } from '@mui/material';

const SPACING_TOP = 20;

const getColorById = (id) => {
  const colors = [theme.colors.primary];
  return colors[id % colors.length];
};

const DraggableItem = ({ item, index, moveUp, moveDown, onIniciarVenda }) => {
  return (
    <View style={[styles.item, { backgroundColor: getColorById(item.ID) }]}>
      <Image source={{ uri: `https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_${item.ID}.jpg` }} style={styles.image} />
      <View style={styles.itemContent}>
        <Text style={styles.itemText}>Ordem Atual: {index + 1}</Text>
        <Text style={styles.itemText}>Lote: {item.LOTEXX}</Text>
        <Text style={styles.itemText}>Descrição: {item.DESLOT}</Text>
        <Text style={styles.itemText}>Pelagem: {item.PELAGE}</Text>
        <Text style={styles.itemText}>RP: {item.RPXXX} - SBB: {item.SBBXXX}</Text>
        {item.CATEGO === "M" && <Text style={styles.itemText}>Categoria: MACHO</Text>}
        {item.CATEGO === "F" && <Text style={styles.itemText}>Categoria: FEMEA</Text>}
        {item.CATEGO === "C" && <Text style={styles.itemText}>Categoria: CASTRADO</Text>}
        {item.CATEGO === "S" && <Text style={styles.itemText}>Categoria: COBERTURA</Text>}
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={() => moveUp(index)} disabled={index === 0}>
          <LoadingButton                    
            loadingPosition="start"
            style={styles.button}
            startIcon={<ArrowUpwardIcon />}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => moveDown(index)} disabled={index === item.length - 1}>
          <LoadingButton                    
            loadingPosition="start"
            style={styles.button}
            startIcon={<ArrowDownwardIcon />}
          />
        </TouchableOpacity>
        <Button 
          variant="text"          
          style={styles.button}
          onClick={onIniciarVenda}
        >
          Iniciar Venda
        </Button>
        
      </View>
    </View>
  );
};

const DraggableList = ({ idLeilao }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const flatListRef = useRef(null);
  const [modalVendaVisible, setModalVendaVisible] = useState(false);
  const [time, setTime] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null); // Para armazenar o item selecionado
  const [value, setValue] = useState('one');

  useEffect(() => {
    if (idLeilao) {
      getData(idLeilao);
    }
  }, [idLeilao]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getData = async (leilaoId) => {
    try {
      setLoading(true);
      const response = await getLotesparaOrdem(leilaoId);
      if (response) {
        response.sort((a, b) => a.LOTEXX - b.LOTEXX);
        setData(response);
      } else {
        console.error('Formato de resposta inesperado:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const moveUp = (index) => {
    if (index > 0) {
      const newData = [...data];
      const item = newData.splice(index, 1)[0];
      newData.splice(index - 1, 0, item);
      setData(newData);
      flatListRef.current.scrollToIndex({ index: index - 1, animated: true });
    }
  };

  const moveDown = (index) => {
    if (index < data.length - 1) {
      const newData = [...data];
      const item = newData.splice(index, 1)[0];
      newData.splice(index + 1, 0, item);
      setData(newData);
      flatListRef.current.scrollToIndex({ index: index + 1, animated: true });
    }
  };

  const handleIniciarVenda = (item) => {
    setSelectedItem(item);
    setModalVendaVisible(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const closeModal = () => {
    setModalVendaVisible(false);
    setSelectedItem(null); // Limpar o item selecionado
  };


  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <View style={styles.loadingContainer}>
          <Text>Carregando...</Text>
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <FlatList
            ref={flatListRef}
            contentContainerStyle={[styles.list, { paddingTop: SPACING_TOP }]}
            data={data}
            renderItem={({ item, index }) => (
              <DraggableItem
                item={item}
                index={index}
                moveUp={moveUp}
                moveDown={moveDown}
                onIniciarVenda={() => handleIniciarVenda(item)}
              />
            )}
            keyExtractor={(item) => item.ID.toString()}
          />
          <Modal
            visible={modalVendaVisible}
            transparent={false}
            animationType="slide"
          >
            <View style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              padding: 10,
              height: 80,
            }}>
              <View style={{
                flexDirection: 'column',   
                justifyContent: 'flex-start',           
                width: '200px',
              }}>              
                <Chip style={{ margin: 5 }} icon={<GavelIcon />} label="58 - Vendidos" />
                <Chip style={{ margin: 5 }} icon={<CancelIcon />} label="2 - Retirados" />
              </View>
              <View style={{
                flexDirection: 'column',   
                justifyContent: 'flex-end',           
                width: '300px',
              }}>    
                <Chip style={{ margin: 5 }} icon={<GavelIcon />} label="Total em Faturamento: R$1.534.00,00" />
                <Chip style={{ margin: 5 }} icon={<GavelIcon />} label="Média Geral: R$15300,00" />
                <Chip style={{ margin: 5 }} icon={<GavelIcon />} label="Média Equinos - Crioulos - Machos: R$20456,00" />
              </View>                
            </View>

            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Lote em Pista</Text>            
              <Text style={styles.modalText}>Tempo em venda: {formatTime(time)}</Text>        
              <Text style={styles.modalText}>
                {selectedItem?.LOTEXX} - {selectedItem?.DESLOT}
              </Text>
              <Image 
                source={{ uri: `https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_${selectedItem?.ID}.jpg` }} 
                style={styles.imageVenda} 
              />
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginRight: 10,
              }}>                          
              <View style={styles.infoContainer}>
                <Text style={styles.modalLabel}>Filiação: {selectedItem?.FILIACAO}</Text>
                <Text style={styles.modalLabel}>Pelagem: {selectedItem?.PELAGE}</Text>
                <Text style={styles.modalLabel}>RP: {selectedItem?.RPXXX} - SBB: {selectedItem?.SBBXXX}</Text>
                {selectedItem?.CATEGO === "M" && <Text style={styles.modalLabel}>Categoria: MACHO</Text>}
                {selectedItem?.CATEGO === "F" && <Text style={styles.modalLabel}>Categoria: FEMEA</Text>}
                {selectedItem?.CATEGO === "C" && <Text style={styles.modalLabel}>Categoria: CASTRADO</Text>}
                {selectedItem?.CATEGO === "S" && <Text style={styles.modalLabel}>Categoria: COBERTURA</Text>}
                
              </View>         
              <View style={styles.infoContainer}>
                <Text style={ styles.modalLabel}>Comentário: {selectedItem?.COMENTARIO}</Text>
              </View>
              </View>
              <View style={styles.bidContainer}>
                <Text style={styles.currentBidText}>Lance Atual</Text>
                <Text style={styles.currentBidAmount}>
                  R${selectedItem?.LANATU.toFixed(2)}
                </Text>                         
              </View>            
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button     
                  style={{
                    backgroundColor: 'green'
                  }}                  
                  variant="contained"
                  onClick={closeModal} 
                  startIcon={<GavelIcon />}
                >
                  Bater Martelo
                </Button>
                <Button style={{ backgroundColor: 'white', color: 'black' }} startIcon={<LibraryAddIcon />}>R$10,00</Button>
                <Button style={{ backgroundColor: 'white', color: 'black' }} startIcon={<LibraryAddIcon />}>R$20,00</Button>
                <Button style={{ backgroundColor: 'white', color: 'black' }} startIcon={<LibraryAddIcon />}>R$50,00</Button>
                <Button style={{ backgroundColor: 'white', color: 'black' }} startIcon={<LibraryAddIcon />}>R$100,00</Button>
                <Button 
                  style={{
                    backgroundColor: 'red'
                  }}                
                  variant="contained"
                  onClick={closeModal} 
                  startIcon={<RemoveDoneIcon />}
                >
                  Remover da Venda
                </Button>
                <Button 
                  style={{
                    backgroundColor: 'silver'
                  }}
                  
                  variant="contained"
                  onClick={closeModal} 
                  startIcon={<CancelIcon />}
                >
                  Voltar aos Lotes
                </Button>
              </ButtonGroup>                                        
            </View>
          </Modal>
          </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',  
    backgroundColor: 'rgba(0, 0, 0, 0.8)',    
    padding: 20,
  },
  modalTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'white',
  },
  modalLabel: {
    color: 'white',
    fontSize: '20',
    fontWeight: 'bold',
  },
  modalText: {
    fontSize: 16,
    color: 'white',
    marginVertical: 5,
    textAlign: 'left',
  },
  imageVenda: {
    width: 300,
    height: 300,
    borderRadius: 10,
    marginVertical: 10,
  },
  buttonLance: {
    width: '400', 
    backgroundColor: "white", 
    color: 'black',
    margin: 10,
  },
  infoContainer: {
    margin: 10,
    width: '50%',
    alignItems: 'flex-start',
  },
  bidContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  currentBidText: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'green',
  },
  currentBidAmount: {
    fontSize: 60,
    fontWeight: 'bold',
    color: 'white',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: innerWidth / 4,
    marginBottom: 20,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  list: {
    padding: 16,
  },
  item: {
    padding: 20,
    marginVertical: 10,
    borderRadius: 8,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    width: 80,
    height: 80,
    marginRight: 10,
    borderRadius: 10,
  },
  itemContent: {
    flex: 1,
  },
  itemText: {
    color: '#fff',
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: '95%',
    margin: 10,
    borderColor: theme.colors.tertiary,
    borderRadius: 5,
    color: 'white',
    backgroundColor: theme.colors.primary,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DraggableList;
