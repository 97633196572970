import React, { useCallback, useContext, useState } from "react";
import { View } from "react-native";
import { debounce, Input, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { useCookies } from "react-cookie";
import { Signin, getUser } from '../../Services/api/AuthServices';
import LogoutIcon from '@mui/icons-material/Logout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { theme } from '../../Global/Theme';
import useMixpanel from '../../hooks/useMixpanel';
import { maskCNPJ, maskCPF } from "../../Global/funcoes";
import { isValidCNPJ } from "../../Global/validaCNPJ";
import { isValidCPF } from "../../Global/validaCPF";

const UserLogin = ({ open, onClose }) => {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [visibleSenhaCampo, setVisibleSenhaCampo] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setState } = useContext(AuthContext);  
  const [cookies, setCookie] = useCookies(["macedo"]);
  const [error, setError] = useState(false);
  const [textMessage, setTextMessage] = useState(null);
  const { trackEvent } = useMixpanel('d70609b52efef69ee6459b93c344f0c9');

  const handleDocument = useCallback(debounce(async (value) => {
    setLoading(true);    
    setError(false);
    setTextMessage(null);
    const cleanValue = value.replace(/[^\d]/g, '');
    const isCNPJ = cleanValue.length >= 14; 
    const maskedValue = isCNPJ ? maskCNPJ(cleanValue) : maskCPF(cleanValue);        
    const isValid = isCNPJ ? isValidCNPJ(cleanValue) : isValidCPF(cleanValue);
    
    if (!isValid) {
        setError(true);
        setTextMessage("CPF ou CNPJ inválido");
        setLoading(false);
        return;
    }
    setCpf(maskedValue); 
    setLoading(false);
  }, 300), []);  

  const handleSubmit = async () => {
    try {
      setError(false);
      setLoading(true);
      const login = await Signin(cpf, senha);
      const user = await getUser(login.id);
      setState({ user, loggedIn: true, token: login.Token });
      setCookie("macedo", user.id, { path: "/", expires: new Date(Date.now() + 900000) });
      setLoading(false);
      onClose();
      trackEvent('Efetuando Login ' + user.id, { Screen: 'SignInScreen' });

    } catch (error) {
      setLoading(false);
      setError(true);
      setTextMessage("Usuário ou senha incorretos, tente novamente");
    }
  };

  return (
    <View style={{ margin: '10px' }}>
      <Typography         
        style={{ color: theme.colors.primary, marginTop: 10, marginLeft: 10 }}>
        Olá.Para continuar, digite seu CPF/CNPJ e Senha
      </Typography>
      <Input
        style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
        value={cpf}
        placeholder='CPF ou CNPJ'
        onChange={e => setCpf(e.target.value)}
        onBlur={() => handleDocument(cpf)}         
      />
      
      <Input
        style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}
        value={senha}
        placeholder='Senha'
        type={visibleSenhaCampo ? 'text' : 'password'}
        endAdornment={
          visibleSenhaCampo ? 
            <VisibilityIcon onClick={() => setVisibleSenhaCampo(!visibleSenhaCampo)} /> : 
            <VisibilityOffIcon onClick={() => setVisibleSenhaCampo(!visibleSenhaCampo)} />
        }
        onChange={e => setSenha(e.target.value)}
      />
      <LoadingButton
        onClick={handleSubmit}
        loading={loading}
        loadingPosition="start"
        style={{
          width: '95%',
          margin: 10,
          borderColor: theme.colors.tertiary,
          borderRadius: 5,
          color: 'white',
          backgroundColor: theme.colors.primary
        }}
        startIcon={<LogoutIcon />}
      >
        Acessar
      </LoadingButton>
      {error && (
        <Typography style={{ color: 'red', textAlign: "center", marginTop: 5, marginLeft: 10, marginBottom: 10, fontWeight: 'bold', fontSize: 12 }}>
          {textMessage}
        </Typography>
      )}      
    </View>
  );
};

export default UserLogin;
