import React, { useCallback, useContext, useEffect, useState } from "react";
import { View } from "react-native";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { useNavigation } from "@react-navigation/native";
import { useCookies } from "react-cookie";
import { Button, CircularProgress, debounce, Input, MenuItem, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { sendEmail } from "../../components/Email/Email";
import styled from '@emotion/styled';
import { theme } from '../../Global/Theme';
import { checkDocument, getEmailporDocumento, getToken, getUser, setTokenRecovery, Signin, updatePassword } from '../../Services/api/AuthServices';
import AlertModal from '../../components/Alerts/AlertModal';
import LogoutIcon from '@mui/icons-material/Logout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockReset from '@mui/icons-material/LockReset';
import useMixpanel from '../../hooks/useMixpanel';
import { isValidCPF } from '../../Global/validaCPF';
import { isValidCNPJ } from '../../Global/validaCNPJ';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUpScreens(props) {  
  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);
  const [cpf, setCpf] = useState(null);
  const [token, setToken] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [visibleToken, setVisibleToken] = useState(false);
  const [visibleSenha, setVisibleSenha] = useState(false);
  const [correctToken, setCorrectToken] = useState(false);
  const [senha, setSenha]= useState(null);
  const [loading, setLoading] = useState(false);
  const { setState, state } = useContext(AuthContext);
  const navigation = useNavigation<any>();
  const [cookies, setCookie, removeCookie] = useCookies(["macedo"]);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(false);  
  const [modalVisible, setModalVisible] = useState(false);
  const [textMessage, setTextMessage] = useState(null);
  const [visibleSenhaCampo, setVisibleSenhaCampo] = useState(false);
  const { trackEvent } = useMixpanel('d70609b52efef69ee6459b93c344f0c9');

  const [loadingSendToken, SetLoadingSendToken] = useState(false);
  const [loadingValidationToken, SetLoadingValidationToken] = useState(false);

  const fontAdapt = innerWidth < 640 ? '9px' : '13px';  

  function maskCPF(cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };
  function maskCNPJ(cnpj) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  function generateTokens() {    
    const token = Math.floor(100000 + Math.random() * 900000).toString();       
    return token;
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleRecovery = () => {
    setType('recovery'); 
    setToken('');        
    const new_token = generateTokens();
    setCodigo(new_token);    
    setVisibleToken(false); 
    setError(false);        
    setInfo(null);         
  };

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleLogout = () => {        
    removeCookie('macedo', { path: '/' });
    setState({ ...state, loggedIn: false });
    window.location.reload();
}

const handleDocument = useCallback(debounce(async (value) => {
  const cleanValue = value.replace(/[^\d]/g, '');
  const isCNPJ = cleanValue.length >= 14; 
  const maskedValue = isCNPJ ? maskCNPJ(cleanValue) : maskCPF(cleanValue);

  console.log(maskedValue);

  const isValid = isCNPJ ? isValidCNPJ(cleanValue) : isValidCPF(cleanValue);
  console.log(isValid);

  if (!isValid) {
      return;
  }

  setCpf(maskedValue); 

  if (maskedValue.length === (isCNPJ ? 18 : 14) && (type === "recovery" || codigo)) {
    console.log('entrou')
      SetLoadingSendToken(true);
      const dateNow = new Date();
      const data = isCNPJ ?? await getEmailporDocumento(maskedValue);

      if (data[0].EMAILX === null) {
          SetLoadingSendToken(false);
          setError(true);
          setInfo('Usuário não encontrado!');
          showModal();
          return;
      } else {
          const dataToken = {
              token: codigo,
              idcliente: data[0].ID.toString(),
              email: data[0].EMAILX,
              data: new Date(dateNow.getTime() + 30 * 60000),
          };

          await setTokenRecovery(dataToken);
          sendEmail(data[0].EMAILX, data[0].NOMEXX, '', 'recuperacao', codigo);
          setVisibleToken(true);
          SetLoadingSendToken(false);
      }
  }
}, 300), []);

const handleToken = async (value) => {
    SetLoadingValidationToken(true);
    setToken(value);
    if (value.length === 6) {
      const data = await getToken(value);      
      if (!data[0]) {
        SetLoadingValidationToken(false);      
        setCorrectToken(false);
        setInfo('Token inválido!');        
        return
      }
      const dataToken = new Date(data[0].DATA);
      if (data[0].TOKEN === null) {
        SetLoadingValidationToken(false);      
        setCorrectToken(false);
        setInfo('Token inválido!');
        return
      }
      if (dataToken.getTime() < new Date().getTime()) {
        SetLoadingValidationToken(false);      
        setCorrectToken(false);
        setInfo('Token expirado!');
        return
      }
      if (data[0].TOKEN === value) {
        SetLoadingValidationToken(false);      
        setCorrectToken(true);
        setVisibleToken(false);
        setVisibleSenha(true);
      }               
      SetLoadingValidationToken(false);      
    }
  };
  

  const ButtonLogin = styled('span')({
    position: 'relative',
    height: '25px',
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
        backgroundColor: 'gray',
        color: theme.colors.secondary,
        borderRadius: '20px',
        borderColor: 'black',
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
        backgroundColor: 'gray',
        color: theme.colors.secondary,
        borderRadius: '20px',
        borderColor: 'black',
      },
      '& .MuiTypography-root': {
        border: '3px solid',
        backgroundColor: 'gray',
        color: theme.colors.secondary,
        borderRadius: '20px',
        borderColor: 'black',

      },
    },
  });

  const Image = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.primary,
  });

  

  
  async function handleSubmit() {
    try {
      setError(false);      
      setTextMessage(null);
      setLoading(true);
      const login = await Signin(cpf, senha);
      const user = await getUser(login.id);            
      sendEmail(user.emailx,user.nomexx,'Login efetuado com sucesso!!!','','')
      setState({ user, loggedIn: true, token: login.Token });
      //  await setKey("user", user);
      //  await setKey("token", login.Token);      
      setCookie("macedo", user.id, { path: "/", 
        expires: new Date(Date.now() + 900000),
       });
      setError(false);      
      handleClose();
      setLoading(false);
      trackEvent('Efetuando Login ' + user.id, { Screen: 'SignInScreen' });
      navigation.navigate("SalesScreen")
    } catch (error) {
      setLoading(false);
      setError(true);
      setVisibleSenha(true);
      setTextMessage("Usuário ou senha incorretos, tente novamente");
    }
  }

  async function handleUpdatePassword() {
    try {
      const user = await checkDocument(cpf)
      setLoading(true);
      await updatePassword(user.id, senha);
      sendEmail(user.emailx,user.nomexx,'','Senha Alterada com Sucesso!!!','')
      await showModal();  
      setLoading(false);          
      setType('login')      
    } catch (error) {            
      setLoading(false);
      setOpen(false);
      return false      
    }
  }

  return (
    <React.Fragment>
      {!props.isMobile ? (        
          props.isSales ? (
            <Button
              style={{
                width: '100%',
                margin: 3,
                borderColor: theme.colors.tertiary,
                borderRadius: 5, 
                color: theme.colors.secondary               
              }}                            
              startIcon={<LogoutIcon />}
              onClick={state.loggedIn ? handleLogout : handleClickOpen}
              
            >              
              Efetuar Login
            </Button>
          )
          : (
        
        <ButtonLogin
          style={{
            width: '150px',
            margin: 3,
            borderColor: theme.colors.tertiary,
            borderRadius: 5,
          }}
        >
          <Image onClick={state.loggedIn ? handleLogout : handleClickOpen}>
            <Typography
              component="span"
              translate='no'
              fontSize={fontAdapt}
              variant="overline"
              color="inherit"
              sx={{
                fontWeight: 'bold',
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              { state.loggedIn ? 'LOGOUT': 'LOGIN'}
            </Typography>
          </Image>
        </ButtonLogin>
        )
      ) :
        (<MenuItem onClick={state.loggedIn ? handleLogout : handleClickOpen}>
          <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
          { state.loggedIn ? 'LOGOUT': 'LOGIN'}
          </Typography>
        </MenuItem>
        )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <View style={{ margin: '15px' }}>
        
          {(type === null) && 
              <>
              <Typography
                style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}
              >
                Olá.Para continuar, digite seu CPF/CNPJ e senha
              </Typography>
              <Input
                style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
                value={cpf}
                placeholder='CPF ou CNPJ'
                onChange={e => setCpf(e.target.value)}
                onBlur={
                  e => handleDocument(e.target.value)
                }                
              />
              <Input
                style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}
                value={senha}
                placeholder='Senha'
                type={
                  visibleSenhaCampo ? 'text' : 'password'
                }
                endAdornment={visibleSenhaCampo ? 
                  <VisibilityIcon 
                    style={{ color: theme.colors.primary }}
                    onClick={() => setVisibleSenhaCampo(!visibleSenhaCampo) }
                  /> : 
                  <VisibilityOffIcon
                    style={{ color: theme.colors.primary }}
                    onClick={() => setVisibleSenhaCampo(!visibleSenhaCampo) }
                  />
                }                              
                onChange={e => setSenha(e.target.value)}
              />
              <LoadingButton                
                onClick={handleSubmit}
                loading={loading}
                style={{
                  width: '95%',
                  margin: 10,
                  borderColor: theme.colors.tertiary,
                  borderRadius: 5, 
                  color: 'white',
                  backgroundColor: theme.colors.primary
                }}                            
                startIcon={<LogoutIcon />}                
              >
                Acessar
              </LoadingButton>
              <Button                                
                style={{
                  width: '95%',
                  margin: 10,
                  borderColor: theme.colors.tertiary,
                  borderRadius: 5, 
                  color: 'white',
                  backgroundColor: theme.colors.primary
                }}                            
                startIcon={<LockReset />}                
                onClick={handleRecovery}
              >
                Recuperar senha
              </Button>              
              <Typography
                translate='no'
                style={{ 
                  color: 'red', 
                  textAlign: "center", 
                  marginTop: 10, 
                  marginLeft: 10,
                  fontWeight: 'bold',
                  fontSize: 12,                  
                }}
              >
                {textMessage}
              </Typography>
            </>
            }
            {(type === 'recovery') &&
              <View
                style={{
                  width: '100%',                               
                }}
              >
                <Typography                                  
                  style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}
                >
                  Digite seu CPF ou CNPJ
                </Typography>
                <Input                  
                  style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
                  value={cpf}
                  placeholder='CPF ou CNPJ'
                  onChange={e => setCpf(e.target.value)}    
                  onBlur={
                    e => handleDocument(e.target.value)
                  }                              
                />
                {
                  cpf.length === 14 || cpf.length === 18 &&                                 
                <Button
                  style={{
                    width: '90%',
                    height: 35,
                    fontSize: 9,
                    margin: 10,
                    borderColor: theme.colors.tertiary,
                    borderRadius: 5,
                    color: 'white',
                    backgroundColor: theme.colors.primary
                  }}   
                  onClick={() => handleDocument(cpf)}               
                  translate='no'
                >
                  Reenviar Token
                </Button>
                }
                {
                  loadingSendToken &&
                  <CircularProgress style={
                    {
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: 10,
                      color: theme.colors.primary,                      
                    }
                  }/>                
                }
                
                {
                  visibleToken && (  
                  <>                
                  <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
                    Digite o CÓDIGO
                  </Typography>
                  <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
                    enviado para seu email
                  </Typography>
                  <Input
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
                    value={token}
                    placeholder='Código'
                    onChange={e => handleToken(e.target.value)}
                  />                  
                  {
                    !correctToken && (
                    <>
                    <Typography style={{ color: 'red', textAlign: "left", marginTop: 10, marginLeft: 10 }}>
                      {info}
                    </Typography>
                    </>
                    )                    
                  }
                  {
                  loadingValidationToken &&
                  <CircularProgress style={
                    {
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: 10,
                      color: theme.colors.primary,                      
                    }
                  }/>                
                  }
                  </>
                  )
                } 
                {
                  visibleSenha && (  
                  <>          
                    <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
                      Digite sua nova senha
                    </Typography>
                    <Input
                      style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
                      value={senha}
                      placeholder='Senha'
                      autoComplete="current-password"                  
                      type='password'
                      onChange={e => setSenha(e.target.value)}
                    />
                  </>
                  )
                }
                <LoadingButton                
                  disabled={!correctToken}
                  style={{
                    width: '90%',
                    margin: 10,
                    borderColor: theme.colors.tertiary,
                    borderRadius: 5, 
                    color: 'white',
                    backgroundColor: theme.colors.primary
                  }}                            
                  startIcon={<LockReset />}                                                            
                  onClick={handleUpdatePassword}                
                >
                  Recuperar Senha
                </LoadingButton>   
                {
                  loading &&
                  <CircularProgress style={
                    {
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: 10,
                      color: theme.colors.primary,                      
                    }
                  }/>                
                  }             
              </View>        

            }
            <AlertModal
              visible={modalVisible}
              message={"Senha alterada com sucesso, faça novamente o login"}
              contact={false}
              onClose={closeModal}
            />            
        </View>
      </Dialog>         
    </React.Fragment>
  );
}