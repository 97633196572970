import React, { useState, useContext } from "react";
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, Dialog, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigation } from "@react-navigation/native";
import { theme } from '../../../Global/Theme';
import { View } from 'react-native';
import styled from '@emotion/styled';
import { AuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCookies } from 'react-cookie';
import PasswordRecovery from "../../../Screens/PasswordRecovery/PasswordRecovery";
import UserLogin from "../../../Screens/UserLogin/UserLogin";
import LockReset from '@mui/icons-material/LockReset';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '5px',
    minWidth: 180,
    color:
      'white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },    
  },
}));

export default function MenuMobile(props) {
    const { setState, state } = useContext(AuthContext);       
    const [cookies, setCookie, removeCookie] = useCookies(["macedo"]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isRecovery, setIsRecovery] = useState(false);
  
    const navigation = useNavigation<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogout = () => {        
      removeCookie('macedo', { path: '/' });
      setState({ ...state, loggedIn: false });
      window.location.reload();
  }
  
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
      setIsRecovery(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHome = () => {
      navigation.navigate('Home')
      handleClose(); 
    };
    const handleRemates = () => {
      navigation.navigate('Remates')
      handleClose();
    };
    const handleCadastro = () => {
      navigation.navigate(state.loggedIn ? 'Detalhes' : 'Cadastrar')
      handleClose();
    };
    const handleQuemSomos = () => {
      navigation.navigate('QuemSomos')
      handleClose();
    };
    const handleParceiros = () => {
      navigation.navigate('Parceiros')
      handleClose();
    };
    const handleContato = () => {
      navigation.navigate('FaleConosco')
      handleClose();
    };   

    return (
    <View>
       <IconButton               
        sx={{ 
          color: props.tag !== "principal" ? ('white') : (theme.colors.primary), 
          fontSize: 'large',           
          width: '50px',                    
        }}        
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <>
          <MenuItem onClick={() => handleHome()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              HOME
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleRemates()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              LEILÕES
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCadastro()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              CADASTRO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleQuemSomos()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              EMPRESA
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleParceiros()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              PARCEIROS
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleContato()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              CONTATO
            </Typography>          
          </MenuItem>   
          <MenuItem onClick={state.loggedIn ? handleLogout : handleClickOpenDialog}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
            {state.loggedIn ? 'LOGOUT' : 'LOGIN'}
            </Typography>          
          </MenuItem>   
          <Dialog          
            open={isRecovery} 
            onClose={handleCloseDialog}
          >
            <PasswordRecovery
              onClose={handleCloseDialog}
            />
            <Button                                
                style={{
                  marginTop: -10,                  
                  marginLeft: 20,                  
                  marginRight: 20,
                  marginBottom: 20,
                  
                  borderColor: theme.colors.tertiary,
                  borderRadius: 5, 
                  color: 'white',
                  backgroundColor: theme.colors.primary
                }}                            
                startIcon={<LockReset />}                
                onClick={() => setIsRecovery(!isRecovery)}
              >
                Voltar ao Login
              </Button>                                      
          </Dialog>                            
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <UserLogin open={openDialog} onClose={handleCloseDialog} />
            <Button                                
                style={{
                  marginTop: -10,                  
                  marginLeft: 20,                  
                  marginRight: 20,
                  marginBottom: 20,
                  
                  borderColor: theme.colors.tertiary,
                  borderRadius: 5, 
                  color: 'white',
                  backgroundColor: theme.colors.primary
                }}                            
                startIcon={<LockReset />}                
                onClick={() => setIsRecovery(!isRecovery)}
              >
                Recuperar senha
              </Button>                          
          </Dialog>                                       
        </>        
      </StyledMenu>
    </View>
  );
}