import React, { useState, useCallback } from "react";
import { View } from "react-native";
import { Button, debounce, Input, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertModal from '../../components/Alerts/AlertModal';
import { getToken, setTokenRecovery, getEmailporDocumento, updatePassword } from '../../Services/api/AuthServices';
import { theme } from '../../Global/Theme';
import { sendEmail } from "../../components/Email/Email";
import { generateTokens, maskCNPJ, maskCPF } from "../../Global/funcoes";
import { isValidCNPJ } from "../../Global/validaCNPJ";
import { isValidCPF } from "../../Global/validaCPF";

const PasswordRecovery = ({ onClose }) => {
  const [cpf, setCpf] = useState('');
  const [token, setToken] = useState('');
  const [senha, setSenha] = useState('');
  const [visibleToken, setVisibleToken] = useState(false);
  const [correctToken, setCorrectToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleClose = () => {
    onClose();
  };
    
  const heigthProgressivo = () => {
    if (visibleToken && correctToken) {
      return '300px';
    } else if (visibleToken) {
      return '200px';
    } else {
      return '130px';
    }
  };

  const handleDocument = useCallback(debounce(async (value) => {
    setError(false);
    setLoading(true);
    let email = null;
    const newToken = generateTokens();    
    const cleanValue = value.replace(/[^\d]/g, '');
    const isCNPJ = cleanValue.length >= 14; 
    const maskedValue = isCNPJ ? maskCNPJ(cleanValue) : maskCPF(cleanValue);        
    const isValid = isCNPJ ? isValidCNPJ(cleanValue) : isValidCPF(cleanValue);
    
    if (!isValid) {
        setLoading(false);
        setInfo('CPF ou CNPJ inválido!');
        setError(true);        
        return;
    }
  
    setCpf(maskedValue); 
    if (maskedValue.length === (isCNPJ ? 18 : 14)) {
         email = await getEmailporDocumento(maskedValue);
         if (!email.length || !email[0].EMAILX) {
            setError(true);
            setLoading(false);
            setInfo('Usuário não encontrado!');
            setModalVisible(true);
            return;
        }
        await setTokenRecovery({ token: newToken, idcliente: email[0].ID.toString(), email: email[0].EMAILX });
        sendEmail(email[0].EMAILX, email[0].NOMEXX, '', 'recuperacao', newToken);
        setVisibleToken(true);
        setLoading(false);
    }
  }, 300), []);  

  const handleToken = async (value) => {
    setToken(value);
    const data = await getToken(value);
    if (!data.length || data[0].TOKEN !== value) {
      setInfo('Token inválido!');
      setCorrectToken(false);
      return;
    }
    setCorrectToken(true);
  };

  async function handleUpdatePassword() {
    try {
      const user = await getEmailporDocumento(cpf)
      console.log(user);
      setLoading(true);
      await updatePassword(
        user[0].ID, senha
      )
      sendEmail(user[0].EMAILX,user[0].NOMEXX,'','Senha Alterada com Sucesso!!!','')
      // showModal();  
      setLoading(false);   
      handleClose();       
      
    } catch (error) {            
      setLoading(false);
    
      return false      
    }
  }

  return (
    <View style={{ margin: '10px', width: '375px', height: heigthProgressivo() }}>
      <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
        Digite seu CPF ou CNPJ
      </Typography>
      <Input
        style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
        value={cpf}
        placeholder='CPF ou CNPJ'
        onChange={e => setCpf(e.target.value)}
        onBlur={
            () => {
                handleDocument(cpf);
            }
        }     
      />
      {!visibleToken && (
        <Button
          style={{
          width: '95%',
          height: 35,
          fontSize: 12,
          margin: 10,
          borderColor: theme.colors.tertiary,
          borderRadius: 5,
          color: 'white',
          backgroundColor: theme.colors.primary
          }}   
          onClick={() => console.log('ok')}               
          translate='no'
        >
            Validar Cadastro
        </Button>
      )}

      {error && info && (
        <>
          <Typography style={{ color: 'red', textAlign: "left", marginTop: 5, marginLeft: 10, fontSize: 9 }}>
            {info}
          </Typography>
        </>
      )}
      {loading &&
        <LoadingButton
            loading
            style={{ 
                width: '90%',
                height: 35,
                fontSize: 9,
                margin: 10, 
                borderColor: theme.colors.tertiary, 
                borderRadius: 5, 
                color: theme.colors.primary, 
                backgroundColor: theme.colors.secondary }}                                 
        />
      }
      {
        cpf.length === 14 || cpf.length === 18 &&                                 
            <Button
                style={{
                width: '90%',
                height: 35,
                fontSize: 9,
                margin: 10,
                borderColor: theme.colors.tertiary,
                borderRadius: 5,
                color: 'white',
                backgroundColor: theme.colors.primary
                }}   
                onClick={() => handleDocument(cpf)}               
                translate='no'
            >
                Reenviar Token
            </Button>
      }
      {visibleToken && (
        <>
          <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
            Digite o CÓDIGO enviado para seu email
          </Typography>
          <Input
            style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
            value={token}
            placeholder='Código'
            onChange={e => handleToken(e.target.value)}
          />
          {!correctToken && token.length === 6 && (
            <>
              <Typography style={{ color: 'red', textAlign: "left", marginTop: 5, marginLeft: 10, fontSize: 9 }}>
                {info}
              </Typography>
            </>
          )}
          {correctToken && (
            <>
              <Typography style={{ color: theme.colors.primary, textAlign: "left", marginTop: 10, marginLeft: 10 }}>
                Digite sua nova senha
              </Typography>
              <Input
                style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
                value={senha}
                placeholder='Senha'
                onChange={e => setSenha(e.target.value)}
              />
              <LoadingButton
                disabled={!correctToken}
                style={{ margin: 10, borderColor: theme.colors.tertiary, borderRadius: 5, color: 'white', backgroundColor: theme.colors.primary }}
                onClick={handleUpdatePassword}
              >
                Recuperar Senha
              </LoadingButton>
            </>
          )}
        </>
      )}
      <AlertModal
        visible={modalVisible}
        message={"Senha alterada com sucesso, faça novamente o login"}
        onClose={() => setModalVisible(false)}
        contact={false}
      />
    </View>
  );
};

export default PasswordRecovery;
